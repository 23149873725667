.backupcodeswarning {
  width: 392px;
  height: 77px;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backupcodeswarning > p {
  width: 338px;
  height: 57px;
  line-height: 19.07px;
  letter-spacing: 0%;
}

.backupcodeswarning > img {
  width: 23.0625px;
  height: 23.0625px;
}

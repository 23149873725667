.BeneAccountMultiApproval_NoAccountFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
  opacity: 1;
  overflow: hidden;
}

.Multifilter_div_container {
  margin-top: 24px;
  margin-left: 15px;
}

.disable_pointer {
  pointer-events: none;
}

.MultiView_toggle {
  margin-left: 15px;
  margin-top: 5px;
  height: 32px !important;
  width: 66px !important;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: #244277;
  border-radius: 5px;
  opacity: 1;
}

.BeneAccount_LeftTextContentNoAccount {
  width: 390px;
  height: 44px;
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.9;
}

.bene_txn_MultiApp_filter_div_left {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 12px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.bene_txn_MultiApp_filter_div_left p {
  top: 149px;
  left: 330px;
  height: 22px;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_MultiApp_filter_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 45px;
  box-shadow: 0px 3px 6px #00000024;
  border: 2px solid #667ca3;
  padding-right: 14px;
  border-radius: 48px;
  opacity: 1;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_viewall_filter_img_div {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 26px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_viewall_filter_img_div > img {
  width: 17px;
  height: 17px;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_MultiApp_close_btn {
  display: flex;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #667ca3;
  border-radius: 50%;
  opacity: 1;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_MultiApp_close_btn > img {
  height: 10px;
  width: 10px;
}

.BeneAccount_RightTextContentNoAccount {
  width: 340px;
  height: 261px;
  opacity: 1;
}

.multi_mid_section {
  margin-top: 25px !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 90%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 10px;
}

#requireScroll {
  overflow-y: auto;
  width: 100%;
  height: 71vh;
}

#requireScroll::-webkit-scrollbar {
  width: 5px;
}

#requireScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

#requireScroll::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 25%;
}

.BenetxnMultiApprovalNavbar {
  width: 100%;
}

.internalMultiApprovalSec {
  width: 100%;
  background-color: #3a5686;
  position: fixed;
  z-index: 99;
  opacity: 1;
  height: 195px;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
  margin-bottom: 0px;
}

.internalTxnMultiApprovalSection {
  width: 1024px;
  height: 90px;
  margin: auto;
}

.filteredClass-transition-open {
  width: 100%;
  height: 126px;
  background: #3a5686 0% 0% no-repeat padding-box;
  /* border-top: 1px solid #000000; */
  margin-top: 208px;
  position: absolute;
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  transition: height 0.2s ease-in, visibility 0s;
  z-index: 99;
}

.filteredClass-transition-close {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.filterdropdown-container {
  background-color: #3a5686;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 99;
}

.toggle_box_InternalTxn {
  border-radius: 5px;
  padding: 8px;
  height: 32px !important;
  width: 33px !important;
}

.shadow_MultiApproval_screen {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.filterdropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
  border-top: 1px solid #1d3a6d;
}

.buttonBene_container_div {
  display: flex;
  margin-top: 55px !important;
}

.beneTxnViewAllMiddleBar {
  display: flex;
  align-items: center;
  height: 60%;
  width: 100%;
}

.beneTxnWholeSection {
  width: 330px;
  display: flex;
  background-color: #506994;
  height: 125px;
  padding-top: 45px;
}

.beneTxnAmountSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 90px;
  height: 49px;
  border-left: 2px solid #1d3a6d;
}

.beneTxnViewAllParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.beneTxnTotalCount {
  display: inline-block;
  transform: rotate(270deg);
  font-style: italic;
  color: #ffffff;
  margin-right: 5px;
  font-size: 15px;
}

.benetxnViewallCount {
  font: normal normal 600 20px/27px Open Sans;
}

.beneTxnTotalAmount {
  width: 180px;
  height: 27px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  margin-left: 20px;
}

.transactionAmountTitle {
  width: 114px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px;
  position: relative;
  right: 30px;
  margin-top: 4px;
}

.clearButton {
  width: 87px;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #f9f9f9;
  border-radius: 8px;
}

.beneTxn_filter_div {
  margin-left: auto;
}

.filter-image {
  width: 12px !important;
  height: 12px;
}

.beneTxnViewAll_search_div {
  width: 40%;
  margin-right: 5%;
}

@media screen and (max-width: 2400px) and (min-width: 1500px) {
  .beneTxnViewAll_search_div {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .beneTxnViewAll_search_div {
    margin-right: 1rem;
  }
}

.bene-txn-no-acc-image {
  height: 250px;
  opacity: 0.9;
}

.bene-txn-no-beneaccount-text,
.no-beneaccount-text2 {
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
}

.no-beneaccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

.selectToProceed {
  margin-top: 220px !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 90%;
  /* margin: 0 115px 0 auto; */
  position: relative;
  margin-bottom: 10px;
}

.bene_txn_MultiApproval_FilterBar {
  background-color: #3a5686;
  margin-top: 201px;
  height: 90px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.bene_txn_MultiApprovalfilterdropdown {
  display: flex;
  justify-content: space-between;

  margin: 0 auto;
  width: 100%;
  max-width: 1024px !important;
  padding-top: 4px;
}

.shadow_viewall_screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.multi-apply {
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 7px !important;
  text-align: center;
  font: normal normal normal 10px/18px Open Sans;
  letter-spacing: 1px;
  color: #c9c9c9;
  width: 87px;
  height: 25px;
}

#txnViewallScrollContainer {
  display: flex !important;
  flex-direction: column !important;
  position: relative;
}

.multi-clear {
  width: 87px;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #f9f9f9;
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
}
.singleViewMob {
  display: table-cell;
  width: 40px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.multi-clear:hover {
  background-color: #506994;
}

/* @media screen and (max-width: 1024px) {
  .beneTxnMultiApprovalSec1 {
    background-color: red;
  }
} */

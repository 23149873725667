.totp_scanner_left {
  width: 472px;
  height: 496px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Blue_5);
}

.totp_scanner_right {
  width: 472px;
  height: 359px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Blue_5);
}

.totp_scanner_left_1 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin-bottom: 20px;
}

.totp_scanner_left_2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin-bottom: 20px;
}

.totp_scanner_left_3 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin-bottom: 20px;
}

.totp_scanner_left_4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.totp_scanner_left_4_qrcode {
  width: 432px;
  height: 176px;
  border-radius: 10px;
  background-color: var(--Blue_7);
}

.totp_scanner_left_4_copycode {
  width: 432px;
  height: 72px;
  padding: 10px 0px 0px 0px;
  border-radius: 10px;
  background-color: var(--Blue_7);
}

.totp_scanner_left_4 > h4 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin: 20px 0px;
}

.totp_scanner_left_4_qrcode > h4 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin: 10px;
}

.totp_scanner_left_4_qrcode_scanner_main {
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hash_copy_msg {
  background-color: var(--Primary_black);
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
  position: absolute;
  border-radius: 5px;
  padding: 4px 6px;
  right: 0px;
  top: 68px;
}

.totp_scanner_left_4_qrcode_scanner {
  width: 120px;
  height: 120px;
  gap: 0px;
  border-radius: 10px;
  background-color: var(--Primary_white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.totp_scanner_left_4_copycode {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.totp_scanner_left_4_copycode > h4 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
}

.totp_scanner_left_4_copycode > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
}

.totp_scanner_left_4_copycode_butt {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-color: var(--Blue_9);
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 5px;
}

.totp_scanner_left_4_copycode_img {
  width: 15.24px;
  height: 15.32px;
}

.entertotpcodes-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entertotpcodes-left {
  width: fit-content;
  height: fit-content;
  margin-right: 50px;
}

.entertotpcodes-left > img {
  width: 472px;
  height: 410.00579833984375px;
}

.entertotpcodes-right {
  width: 472px;
  height: 511px;
  border-radius: 40px;
  margin-left: 25px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-entertotpcodes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.entertotpcodes-right-1 {
  width: 200px;
  height: 54.94505310058594px;
  margin-bottom: 30px;
  align-self: center;
}

.entertotpcodes-right-2 {
  margin-bottom: 4px;
}

.entertotpcodes-right-3 {
  margin-bottom: 20px;
}

.entertotpcodes-right-4 {
  width: 392px;
  height: 42px;
  border-radius: 10px;
  position: relative;
}

.entertotpcodes-right-6 {
  width: 392px;
  height: 38px;
  border-radius: 75px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entertotpcodes-right-6:hover {
  opacity: 0.8;
}

.entertotpcodes-right-6 > img {
  width: 30px;
  height: 30px;
}

.entertotpcodes-right-4 > label {
  position: absolute;
  margin-left: 15px;
  top: 8px;
  transition: all 0.1s ease;
  padding: 0px 3px;
  cursor: text;
  z-index: 2;
}

.entertotpcodes-right-5 {
  cursor: pointer;
  text-underline-offset: 2px;
}

.input_Box_logintotp {
  width: 412px;
  height: 42px;
  background-color: transparent;
  outline: none;
}

.logintotponoff {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 30%;
  cursor: pointer;
}

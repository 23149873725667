.internal_amount_first_div {
  border-radius: 20px;
  background-color: #3a5686;
  margin-bottom: 20px;
}

.second_side_div {
  margin-bottom: 20px;
  width: 472px;
  height: 169px;
  position: relative;
}

.second_side_div_res {
  height: 169px;
  position: relative;
}

.internal_amount_div {
  max-width: 29.5rem;
  height: fit-content;
  background-color: var(--Enter_ammount_bg_green);
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 20px;
}
.internal_amount_div_res {
  max-width: 100%;
  height: fit-content;
  background-color: #3b965e;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.internal_amount_div_child {
  width: 27rem;
  /* margin: 20px 0px;
  padding: 0px 20px; */
}

.internal_amount_word {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-top: 15px;
}

.internal_amount_div_child_bottom {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.internal_amount_div_child_bottom_first {
  width: 432px;
  height: 59px;
  background-color: #7dc782;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.internal_radio_1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.internal_amount_div_child_bottom_para {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin: 20px 0px;
}

.internal_radio_amount > h4 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  margin-bottom: 5px;
}

.internal_radio_amount > h6 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
}

.internal_circle_radio[type="radio"] {
  width: 17.75px;
  height: 17.75px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #f9f9f9;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  position: relative;
}

.internal_circle_radio[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 10.4px;
  height: 10.4px;
  background-color: #f9f9f9;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* animation: appear 0.8s; */
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #f9f9f9;
  }
  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #f9f9f9;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #f9f9f9;
  }
  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #f9f9f9;
  }
}

.Inter_settle_main_div_container_img_2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 38.5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity: 1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Inter_settle_main_div_container_img_2 > img {
  width: 65px;
  height: auto;
}

.internal_isRtgs_seleted {
  width: 100%;
  height: fit-content;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.beneContact_Main_internal {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.beneContact_Main_internal_res {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.divideAmount_internal {
  width: 472px;
  max-height: auto;
  /* margin: 0 20px 20px 20px; */
  overflow-y: auto;
}

.divideAmount_internal_res {
  width: 100%;
  max-height: auto;
  /* margin: 0 20px 20px 20px; */
  /* overflow-y: auto; */
}

.owner-Fetch-Balance {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.owner-Fetch-Balance::after {
  content: "";
  position: absolute;
  height: 12px;
  width: 1px;
  background-color: #dadada;
  top: 5px;
  right: -10px;
}

.source-account-latest-balance {
  display: flex;
  width: fit-content;
  position: relative;
  justify-content: center;
  align-items: center;
}

.internal_reload {
  width: 10.61px;
  height: 10.61px;
  color: #dadada;
  cursor: pointer;
}

@media only screen and (max-width: 360px) {
  .internal_new_balance {
    width: 118px !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 380px) {
  .internal_new_balance {
    width: 128px !important;
  }
}

.internal_new_balance {
  width: 138px;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
}

.internal_bene_name {
  width: 176px;
  height: 19px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #f9f9f9;
  text-transform: lowercase;
}

.internal_bene_name::first-line {
  text-transform: capitalize;
}

.internal_amount_button {
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.internal_amount_div_child_bottom_first_part {
  width: 432px;
  height: 59px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.internal_amount_div_child_bottom_first_part_1 {
  width: 206px;
  height: 59px;
  padding: 10px;
  gap: 0px;
  border-radius: 10px;
  background-color: #69b76f;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload_cancel_internal {
  width: 20px;
  height: 20px;
  position: relative;
  left: 253px;
  cursor: pointer;
  top: -12px;
}
.internal_date_heading_leftm_txn {
  display: flex;
  text-align: left;
  font: normal normal normal 16px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 10px;
  margin-right: 20px !important;
  font-weight: 400;
}

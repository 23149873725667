.tag-search {
  width: 520px;
  height: 34px;
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 2px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-partition-one {
  flex: 65%;
}

.tag-partition-two {
  display: flex;
  flex-direction: row;
  padding-inline: 10px;
}

.tag-partition-one-css {
  width: 182px;
  height: 22px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.input-custom-search {
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  width: 100%;
  box-sizing: border-box;
  color: var(--Primary_white);
  font: normal normal normal 14px / 19px "Open Sans", sans-serif;
  outline: none;
}

.tag-down-search {
  width: 14px;
  height: 14px;
}

.btn-custom-primary {
  width: 89px !important;
  height: 24px !important;
  background: var(--Secondary_grey) 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  text-align: center !important;
  font: normal normal 400 10px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
  margin: 3px 5px 5px 5px !important;
  padding: 9px !important;
}

.btn-custom-secondary {
  width: 89px !important;
  height: 24px !important;
  background: var(--Blue_8) 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  text-align: center !important;
  font: normal normal 400 10px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
  margin: 3px 5px 5px 5px !important;
  padding: 9px !important;
}

.tag-list {
  display: flex;
  flex-direction: column;
}

.existing-tag {
  display: flex;
  flex-direction: row;
}

.existing-tag > p {
  font: normal normal normal 400 12px/19px Open Sans;

  color: var(--Primary_grey);
}

.tag-list > p {
  font: normal normal normal 400 12px/19px Open Sans;

  color: var(--Primary_grey);
}

.viewall-tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: var(--Blue_6);
  box-sizing: border-box;
  grid-column: span 1;

  margin: 0;
  padding: 5px 5px 5px 10px !important;
  width: fit-content;
  cursor: pointer;
}

.cofirmhover:hover {
  background-color: var(--Button_green_hover) !important;
}

.viewall-tag-box-item:hover {
  background-color: var(--Blue_6_hover);
}

.Assign-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  justify-content: center;
  background: var(--Blue_6);
  box-sizing: border-box;
  grid-column: span 1;
  height: 30px !important;
  width: fit-content;
  cursor: default;
  color: var(--Primary_white);
}

.viewall-tag-box-item > div {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  font: normal normal normal 400 12px/17px Open Sans;

  color: var(--Primary_white);
}

.tag-bottom {
  position: absolute;
  height: 80px;

  z-index: 6;

  width: 560px;

  bottom: 0;
  background: var(--Blue_4);
}

.tag-bottom-expand {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  height: 392px !important;
  padding: 10px 30px;
  z-index: 6;
  gap: 13px;
  margin-bottom: 76px;
  width: 560px;
  border-bottom: 1px solid var(--Blue_2) !important;
  bottom: 0;
  background: var(--Blue_4);
  align-content: flex-start;
}

.tag-bottom-nodata {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  height: auto !important;

  z-index: 6;
  padding: 10px;
  margin-bottom: 75px;
  width: 560px;
  border-bottom: 1px solid var(--Blue_2) !important;
  bottom: 0;
  background: var(--Blue_4);
  border-radius: 16px 16px 0px 0px;
}

.show-btn {
  height: 26px;
  margin: 10px 0 0 100px;
  border-right: 1px solid var(--Blue_2) !important;
}

.main-btn {
  display: flex;
  margin: 0px 0 0 0px;
  justify-content: center;
  align-items: center;
}

.show_overbtn {
  width: 66px;
  height: 26px;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;
  background-color: var(--Blue_2) !important;
  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  margin-right: 1rem !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 5px 10px !important;
}

.show_overbtn > div {
  min-width: 31px;
}

.show_overbtn:hover {
  background-color: var(--Blue_2_hover) !important;
}

.cancel_overbtn {
  width: 78px;
  height: 32px;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;
  text-decoration: underline !important;
  padding-top: 7px;
  padding-left: 10px;
  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  cursor: pointer;
}

.confirm_overbtn {
  width: 79px;
  height: 32px;
  padding: 8px 16px;

  text-align: center !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;

  font: normal normal normal 400 12px/19px Open Sans !important;
  border-radius: 2rem !important;
  text-transform: none !important;
  margin-left: 1rem !important;
}

.tagup_icon {
  width: 12px !important;
  height: 12px !important;
  margin-left: 2px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
}

@media screen and (max-width: 800px) {
  .main-btn {
    background-color: var(--Blue_5);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 8px;
    gap: 20px;
  }
  .cancel_overbtn {
    border: 1px solid var(--Primary_white);
    text-decoration: unset !important;
  }
}

.Owner_tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;

  margin: 10px 20px 10px 0;
  padding: 10px !important;
  width: fit-content;
  cursor: pointer;
}

.usertag_show{
    background: var(--Blue_4);
    border-bottom: 1px solid var(--Blue_2) !important;
    /* bottom: 0; */
    padding: 20px;
    /* height: 375px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    width: 511px;
   z-index: 6;
}

.popup_res {
    /* transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden; */
  background-color: var(--Blue_5);
  /* z-index: 50px; */
  position: absolute;
  /* bottom: 71px; */
  width: 100%;
  height: calc(100% - 136px);
}

@media (max-width: 800px) {
  .popup_res {
      height: calc(100% - 100px);
      overflow-y: auto;
  }
  .popup_res_all{
    bottom: -14px;
  }
}


.show_btn_res{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.125rem;
  height: 1.625rem;
  background-color:var(--Blue_2);
}

@media (max-width: 400px) {

  .show_btn_res {
    width: 6.125rem !important;
  }
}

.popup_res_all{
   /* transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out; */
    /* overflow: hidden; */
    /* transform: translateY(50px); */
    background-color: var(--Blue_5);
   
    position: absolute;
    /* bottom: 71px; */
    width: 100%;
    height: calc(100% - 71px);
}

.Usertag-value-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  justify-content: center;
  background: var(--Blue_6);
  box-sizing: border-box;
  grid-column: span 1;
  height: 30px !important;
  width: fit-content;
  cursor: default;
  color: var(--Primary_white);
  gap: 20px;
}

.usertag_border{
  border-right: 1px solid var(--Blue_2) !important;
    height: 26px;
    margin: 10px 0 0 100px;
}

.usertag_show_hide{
    background: var(--Blue_4);
    border-bottom: 1px solid var(--Blue_2) !important;
    bottom: 0;
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    position: absolute;
    /* width: 560px; */
    z-index: 6;
}

/* @media (max-width: 1024px) {
  .OwnerTagArrow-responsive {
    padding: 10px;
  }
}

@media (max-width: 370px) {
  .OwnerTagArrow-responsive {
    margin-left: 10px;
    margin-right: 10px;
  }
} */

.tag-box-item {
  display: flex;
  flex-direction: row;
  border-radius: 20px !important;
  background: #3a5686;
  box-sizing: border-box;
  grid-column: span 1;

  margin: 10px 20px 10px 0 !important;
  padding: 10px !important;
  width: fit-content;
  cursor: pointer;
}

.tag-box-item > div {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  font: normal normal normal 400 12px/17px Open Sans;
  color: #f9f9f9;
}

.dropdown-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
}

.dropdown-div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-content {
  background-color: #667ca3;
  max-height: 135px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 9;
  height: 90px;
  left: -15.5px;
  top: 25px;
  margin-top: 6px;
  border-top: none;
}

.dropdown-content::before {
  content: "";
  position: absolute;
  top: 27px;
  left: 50%; /* Center the border */
  width: 90%; /* Adjust the length of the border */
  height: 1px; /* Border thickness */
  background-color: #244277; /* Border color */
  transform: translateX(-50%); /* Center the border */
}

.dropdown-item1 {
  padding: 5px;
  margin: 0px 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item {
  padding: 8px 8px;
  cursor: pointer;
  color: #f9f9f9;
}

.dropdown-item {
  /* margin-bottom: 20px; */
  padding: 5px;
  margin: 0px 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-img-div {
  display: row !important;
  align-items: center;
}

.dropdown-item:hover {
  /* background-color: #8596B5; */
  padding: 5px;
  border-radius: 5px;
}

.dropdown-item img,
.dropdown-item1 img {
  width: 16px;
  height: 16px;
}

.dropdown-item p,
.dropdown-item1 p {
  /* Option 1 */

  width: 170px;
  height: 16px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #f9f9f9;
}

.dropdown-div-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 10px;
}

.dropdown-content-responsive {
  position: absolute;
  background-color: #667ca3;
  width: 100%;
  max-height: 155px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 0px 0px 10px 10px;
  z-index: 9;

  top: 25px;
  border: 1px solid #ff5860;
  padding-top: 5px;
  border-top: none;
}

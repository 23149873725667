.ownerCard {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 250px;
}

.card_onhover {
  display: none;
  padding-top: 6px;
  border-top: 1px solid var(--Blue_3);
}

.ownerSelectownerCard {
  width: 238px;
  height: 105px;
  border-radius: 10px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  background-color: var( --Filter_chip_fill);
  box-sizing: border-box;
  margin: 10px;
  padding-right: 1px !important;
  cursor: pointer;
  transition: height 0.1s;
}


.ownerSelectownerCard.refresh-error:hover {
  position: absolute;
  border: 1px solid var(--Failed);
  z-index: 2;
  width: 238px;
  height: 310px;
}



.ownerSelectownerCard.refresh-not-error:hover {
  position: absolute;
  border: 1px solid var(--Failed);
  z-index: 2;
  width: 238px;
  height: 290px;
}


.owner-tag-card {
  /* width: 100%; */
  height: 105px;
  background-color: var(--Blue_7);
  border-radius: 10px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  background-color: var(--Blue_6);
  box-sizing: border-box;
  margin: 10px;
  padding-right: 1px !important;
  cursor: pointer;
  transition: height 0.1s;

}

.assign-tags-list {
  /* standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat */
  margin-right: 16px;
  /* width: 218px; */
  height: auto;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: var(--Primary_white);

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.ownerSelectownerCard:hover .card_onhover {
  transition: all 0.2s ease 0.2s;
  display: block;
}

.owner_viewall-details {
  padding: 10px 0px 0px 15px;
}

.assign-tags-div {
  /* Frame 11342 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 4px;

  width: 100%;
  height: auto;

  /* Inside auto layout */
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  padding: 6px 0px 10px 15px;

  border-top: 1px solid var(--Blue_3);
}

.assign-tags-title {
  display: flex;
  gap: 5px;
  align-items: center;
}

.assign-tags-title img {
  /* assign_tag without circle */

  width: 12px;
  height: 12px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.assign-tags-div > .assign-tags-title > p {
  /* Balance updated */

  width: 64px;
  height: 14px;

  /* Web Font/10 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  color: var(--Primary_grey);

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.assign-tags-list-listView {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid #ff5860;
  border-top: none; */
  background-color: var(--Blue_1);
  border-top: 1px solid var(--Blue_7);
  padding: 10px 0px;
  border-radius: 0px 0px 10px 10px;
}
.assign-tags-list-listView img {
  /* Subtraction 470 */

  width: 12px;
  height: 12px;
  margin-left: 20px;
}

.assign-tags-list-listView p {
  /* standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat, standard, main, ICICI, HDFC, akshat */

  width: 336px;
  height: auto;
  margin-left: 20px;
  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: var(--Primary_white);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card_refresh {
  width: 22px;
  height: 18px;
}
.card_refresh-img {
  width: 22px;
  height: 20px;
  object-fit: cover;
  border-radius: 5px;
}

.balance-container {
  display: flex;
  gap: 10px;
  position: relative;
}

.borders-div {
  margin: 3px 10px;
  height: 13px;
}

.viewall-date-div {
  color: var(--Primary_grey);
  font: normal normal normal 10px/14px Open Sans;
  margin-bottom: 20px;
}

.viewall-balance-div {
  font: normal normal normal 12px/16px Open Sans;
  color: var(--Primary_grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px 0px 10px 15px !important;
  gap: 10px;
  margin-right: -1px;
}

.views-all-user {
  white-space: nowrap;
  height: 40px;
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.viewall-bank-acc-div {
  max-width: 60px;
  width: 50px;
  font: normal normal 600 16px/22px Open Sans;
  padding-bottom: 2px;
}

.favourite-icon-gridView {
  position: absolute;
  top: 35px;
  right: 10px;
}

.favourite-icon-gridView img {
    width: 16px;
    height: 15px;
}


.not-available {
  color: var(--Primary_white) !important;
  font: normal normal 400 16px/19px Open Sans;
  margin-bottom: 4px;
}

.boxs-status {
  width: 5px;
  height: 15px;
  background-color: var(--Settled);
  border-radius: 0px 5px 0px 0px;
}

.boxheader:hover .assigned {
  display: block;
}

.assigned {
  display: none;
  width: auto;
  padding: 0px 5px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  letter-spacing: 0.5px;
}

.label-divs {
  display: flex;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.viewall-amount-div {
  visibility: visible;
}

.view-all-card-container {
  margin-right: 0px !important;
  width: 90px !important;
  height: 26px !important;
}

.assigned-pending-box,
.assigned-approved-box,
.assigned-blocked-box,
.assigned-archived-box,
.assigned-denied-box ::first-letter {
  text-transform: capitalize;
}

.assigned-pending-box {
  background-color: rgba(49, 229, 229, 0.2);
  color: rgba(49, 229, 229, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.assigned-approved-box {
  background-color: rgba(77, 221, 55, 0.2);
  color: rgba(77, 221, 55, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.assigned-denied-box,
.assigned-blocked-box {
  background-color: rgba(255, 88, 96, 0.2);
  color: rgba(255, 88, 96, 1);
  border-radius: 0px 3px 0px 0px;
  font-size: 9px;
  text-transform: capitalize;
}

.assigned-archived-box {
  background-color: rgba(207, 207, 207, 0.2);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
  color: rgba(207, 207, 207, 1);
}

.card_info-div {
  width: 22px;
  height: 18px;
}

.card_info-div > img {
  border-radius: 5px;
  width: 22px;
  height: 20px;
  object-fit: cover;
}

.card_balance_last_div {
  padding-right: 5px;
  display: flex;
  gap: 5px;
}

.account_text {
  font: normal normal normal 10px/14px Open Sans;
  color: var(--Primary_grey);
  padding-top: 5px;
}

.split_view_header {
  z-index: 1;
  height: 25px;
  background-color: var(--Blue_3);
  top: 0;
  position: sticky;
  color: var(--Primary_grey);
  font: normal normal normal 14px/19px Open Sans;
  display: flex;
  width: 100%;
  /* padding-top: 10px; */
  padding-left: 15px;
}

.noaccountfound {
  padding: 0 0 15px 15px;
  color: var(--Primary_grey);
  font: normal normal normal 14px/19px Open Sans;
}

.owner_split_div {
  border: 1px solid #031f4f;
  height: auto;
  min-height: 320px;
}

.animation_test {
  animation: test 0.2s forwards ease-in;
}

@keyframes test {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.animation_test2 {
  animation: test2 0.2s forwards ease-in;
}

@keyframes test2 {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 960px) {
  .ownerCard {
    width: 100% !important;
  }
}

@media screen and (max-width: 700px) {
  .ownerCard {
    width: 93% !important;
  }
}

@media screen and (max-width: 540px) {
  .ownerSelectownerCard {
    width: 100% !important;
    margin: 0 !important;
  }

  .views-all-user {
    width: 30.5rem !important;
  }
}

.custom_create_totp {
  color: var(--Primary_white) !important;
  background-color: var(--Blue_7) !important;
  width: 126px !important;
  height: 60px !important;
  text-transform: none !important;
  text-align: left !important;
  font: normal normal 16px/22px Open Sans !important;
  letter-spacing: 0px !important;
  border-radius: 0px !important;
}

.custom_create_totp:hover {
  opacity: 0.8;
}

.custom_create_next_stepper {
  width: 126px !important;
  height: 60px !important;
  text-transform: none !important;
  text-align: left !important;
  font-size: var(--Font_16);
  font-family: "Open Sans";
  letter-spacing: 0px !important;
  border-radius: 0px !important;
}

.device-card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.deviceCard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
  /* margin-top: 45px; */
  background-color: var(--Blue_3);
  justify-content: center;
}

.devices-middle_section_container {
  display: flex ;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 13rem auto 0 6rem;
  background-color: var(--Blue_3);
  position: fixed;
  z-index: 4;
  overflow: hidden;
  /* height: 48px; */
  /* background-color: red; */
}

.devices-middle_section_container .devices-search-div {
  width: 30px;
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.devices-middle_section_container .devices-search-div img {
  width: 14px;
  height: 14px;
}

.multiSelectDeviceCard {
  width: 238px;
  height: 101px ;
  border-radius: 10px;
  grid-column: span 1;
  background-color: var(--Blue_6);
  box-sizing: border-box;
  padding: 10px ;
  margin: 10px;
  padding-right: 1px ;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.multiSelectDeviceCard:hover {
  background-color: var(--Blue_6_hover);
}

.browser-logo-div {
  background:var(--Primary_white);
  box-shadow: -5px -5px 11px #71717133 ;
  opacity: 1;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px ;
  cursor: pointer;
 border-radius: 10px 0px;


  /* Frame 4 */

/* Auto layout */
/* Frame 4 */

/* Auto layout */
/* display: flex;
flex-direction: row;
align-items: center;
padding: 10px;
gap: 10px;

position: absolute;
width: 40px;
height: 40px;
right: 0px;
bottom: 0px;

background: #F9F9F9;

flex: none;
order: 2;
flex-grow: 0;
z-index: 2; */


}

.browser-logo-div img {
  width: 22px;
  height: 30px;
  opacity: 1;
}

@media screen and (min-width: 1900px) {
  .multiSelectDeviceCard {
    width: 266px;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .multiSelectDeviceCard {
    width: 254px;
  }
}

.device-viewall-date-div {
  color: var(--Primary_grey);
  font-size: 10px;
  margin-bottom: 20px;
}

.device_viewall_name_div {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #b196fa;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* overflow: hidden; */
  text-transform: lowercase !important;
}

.device_viewall_name_div::first-line {
  text-transform: capitalize !important;
}

.device-view-all-user {
  white-space: nowrap;
  width: 13rem;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.device_viewall-bank-acc-div {
  color: var(--Primary_white);
  font-size: 12px;
  margin-bottom: 4px;
}

.device_viewall_box-status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
}

.multiSelectDeviceCard:hover .device_viewall_assigned {
  display: block;
}

.device_viewall_assigned {
  display: none;
  width: 60px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
}

.device_viewall_label-div {
  display: flex;
}

.device-view-all-card-container {
  margin-right: 0px !important;
  width: 98px !important;
  height: 40px !important;
}

.device-card-top-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5px;
}



.device-card-bottom-div {
  display: flex;
  flex-direction: row;
  /* margin-top: 10px; */
  padding: 0 5px;
}



/*  */

.device-card-titles {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  line-height: 19px;
  /* font-weight: 400;
  width: 106px;
  height: 19px;
  line-height: 19px; */
}

.device-card-sub {
  font: normal normal 600 9px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  font-size: 10px;
  opacity: 1;
  margin-top: -5px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 1px;
}

@media screen and (min-width: 290px) and (max-width: 800px) {
  .devices-middle_section_container {
    margin: 6rem auto 0 3rem !important;
  }
}
@media screen and (min-width: 820px) and (max-width: 1120px) {
  .devices-middle_section_container {
    margin-left: 3rem !important;
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .devices-middle_section_container {
    width: 79% !important;
    margin-left: 9rem !important;
  }
  .deviceCardInner {
    width: 83% !important;
  }
}
@media screen and (min-width: 1518px) and (min-height: 800px) {
  .devices-middle_section_container {
    /* width: 71% !important; */
    margin-left: 6rem !important;
  }
  .deviceCardInner {
    width: 92% !important;
  }
}
@media screen and (min-width: 1440px) and (min-height: 900px) {
  .devices-middle_section_container {
    width: 75% !important;
    margin-left: 3rem !important;
  }
  .deviceCardInner {
    width: 98% !important;
  }
}
@media screen and (min-width: 1900px) and (max-width: 2400px) {
  .devices-middle_section_container {
    margin-left: 3rem !important;
  }
}
@media screen and (min-width: 2500px) and (max-width: 2900px) {
  .devices-middle_section_container {
    margin-left: 4rem !important;
  }
}
@media screen and (min-width: 2900px) and (max-width: 3860px) {
  .devices-middle_section_container {
    margin-left: 5rem !important;
  }
}
.deviceCardInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 81%;
  margin: 0 auto;
}

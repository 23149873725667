#InternalAccountRequireApprovalPage {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 100vh;
}

#viewAllScrollContainerBar {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.InternalAccountApproval_Acdiv1 .Internalapprovaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}
.InternalAccountApproval_Acdiv1 .Internalapprovaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}
.InternalAccountApproval_Acdiv1 .Internalapprovaltext1 p {
  width: 163px;
  height: 19px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  margin-top: 20px;
  margin-left: 20px;
  opacity: 1;
}
.InternalAccountApproval_Acdiv1 .Internalapprovaltext1 p::first-line {
  text-transform: capitalize !important;
}
.InternalAccountApproval_Acdiv1 .Internalapprovaltext1 span {
  width: 128px;
  white-space: nowrap;
  height: 19px;
  margin-top: 20px;
  padding-left: 30px;
  margin-right: -20px;
  font: normal normal normal 14px/19px Open Sans;
  color: var(--Primary_white);
  opacity: 1;
}
.ApprovalAcdivm1 .Internalapprovaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}
#BeneviewAllScrollContainerBarbene {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  /* overflow: auto; */
  /* height: 62vh; */
  width: 100%;
}

#BeneviewAllScrollContainerBarbene .infinite-scroll-component__outerdiv {
  /* height: 100vh; */
}

#BeneviewAllScrollContainerBarbene .infinite-scroll-component {
  overflow-x: hidden;
}

.benefbtnconfandCan .expentraLoaderclass,
.BeneTxn_benefbtnconfandCan .BeneTxn_expentraLoaderclass {
  position: relative;
  width: 68px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefbtnconfandCan .expentraLoaderclass {
  border-radius: 20px;
  opacity: 0.55;
}

.bene-require-expentraLoaderclass {
  position: relative;

  width: 68px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  opacity: 1;
}

.bene-require-expentraLoaderclass img {
  width: 40px;
  height: 40px;
}

.BeneTxn_benefbtnconfandCan .BeneTxn_expentraLoaderclass {
  opacity: 1;
}

.expentraLoaderclass img {
  width: 20px;
  height: 40px;
}

.InternalAccountApprovalNavbar {
  width: 100%;
  height: 208px;
}

.InternalAccountApprovalsec1 {
  width: 100%;
  background: linear-gradient(to right, #506994 50%, #3a5686 50%);
  z-index: 99;
  opacity: 1;
  height: 208px;
}

.InternalAccountApprovalsec2 {
  width: 1024px;
  display: flex;
  background: linear-gradient(to right, #506994 20%, #3a5686 20%);
  height: 208px;
  justify-content: flex-end;
  align-items: end;
  margin: auto;
}

.InternalAccountApprovalsec2 .InternalAccountapprovalTopbar {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
}

.InternalAccountApprovalsec2 .InternalAccountfirstheaderApproval {
  width: 210px;
}

.InternalAccountApprovalsec2 .InternalAccountSecondheaderApproval {
  display: flex;
  align-items: center;
}

.InternalAccountApprovalsec2 .InternalAccountapprovalParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.InternalAccountApprovalsec2 .InternalAccountapprovalchild1 {
  width: 51px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  white-space: nowrap;
  margin-left: 10px;
}

.InternalAccountApprovalsec2 .InternalAccountapprovalchild2 {
  width: 100px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApprovalsec2 .InternalAccountnavbarleft {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 4px;
}

.InternalAccountApprovalsec2 .InternalAccountnavbarright {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 04px;
}

.InternalAccountApprovalsec2 .approvedtag1 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: var(--Settled);
  opacity: 1;
}

.InternalAccountApprovalsec2 .approvedtag2 {
  width: 55px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApprovalsec2 .approvedtag3 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff5860;
  opacity: 1;
}

.InternalAccountApprovalsec2 .approvedtag4 {
  width: 48px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

@media (max-width: 610px) {
  .declinedReasion {
    flex-grow: 1;
    /* margin-bottom: 20px; */
  }

  .Beneinputfield {
    flex-grow: 1;
  }

  .note-text {
    flex-grow: 1;
    margin-right: 30px;
  }

  .benefbtnconfandCan {
    flex-grow: 1;
    margin-right: 0.7rem !important;
  }
}
@media (max-width: 1520px) {
  .allAprovalAcountInfo {
    height: 76vh;
  }
}

.InternalAccountapprovedAcoutDetails {
  display: flex;
  flex-direction: row;
  width: 1024px;
  height: 200px;
  background: #314d7e;
  border-radius: 30px;
  margin-bottom: 20px;

  margin: 20px auto;
}

.InternalAccountApproval_Acdiv1 {
  width: 341px;
  height: 100%;
  /* border-right: 2px solid #506994; */
}

.InternalAccountApproval_Acdiv1 .Approvalsection1 {
  width: 100%;
  height: 100%;
}

.InternalAccountApproval_Acdiv1 .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.InternalAccountApproval_Acdiv1 .approvaltext1 p {
  width: 163px;
  height: 19px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  margin-top: 20px;
  margin-left: 20px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv1 .approvaltext1 p::first-line {
  text-transform: capitalize !important;
}

.InternalAccountApproval_Acdiv1 .approvaltext1 span {
  width: 108px;
  height: 19px;
  margin-top: 20px;
  padding-left: 55px;
  margin-right: -20px;
  font: normal normal normal 14px/19px Open Sans;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv1 .approvaltext2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.InternalAccountApproval_Acdiv1 .approvaltext2 .z1 {
  width: auto !important;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  margin-left: 20px;
}

.InternalAccountApproval_Acdiv1 .approvaltext2 .z2 {
  width: auto !important;
  height: 17px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

.InternalAccountApproval_Acdiv1 .approvaltext3 {
  margin-top: -20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InternalAccountApproval_Acdiv1 .approvaltext3 p {
  /*width: 229px;*/
  width: 300px;
  height: 19px;
  margin-top: 20px;
  overflow: hidden;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv1 .approvaltext3 span {
  width: 44px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApproval_Acdiv1 .approvaltext4 {
  margin-top: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InternalAccountApproval_Acdiv1 .approvaltext4 p {
  width: 300px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.InternalAccountApproval_Acdiv1 .approvaltext4 span {
  width: 111px;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 50px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 {
  width: 341px;
  height: 100%;
  color: white;
  /* border-right: 2px solid #506994; */
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 {
  width: 100%;
  height: 100%;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv1 {
  display: flex;
  margin-left: 15px;
  margin-top: -7px;
  justify-content: space-between;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv1 p {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 22px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2
  .Approvalsection2
  .approvalDiv1
  .approvalDiv1span {
  height: 19px;
  margin-top: 20px;
  text-align: -webkit-match-parent;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv2 {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv2 .n1 {
  width: auto;
  height: 17px;
  margin-left: 15px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv2 .n2 {
  width: 72px;
  height: 17px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  /* margin-right: 3px; */
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv3 p {
  width: 150px !important;
  height: 19px;
  margin-left: 15px;

  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv3 span {
  width: 81px;
  height: 19px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv4 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv4 p {
  width: 120px;
  height: 17px;
  margin-left: 15px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv4 span {
  width: 34px;
  height: 17px;
  margin-right: 15px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv5 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.approvalDiv5_internal {
  display: flex;
  justify-content: space-between;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv5 p {
  width: 93px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-left: 15px;
}

.approvalDiv5_internal p {
  width: 93px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-left: 20px;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv5 span {
  width: 54px;
  height: 17px;
  margin-top: 4px;
  margin-left: 15px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.approvalDiv5_internal span {
  width: 54px;
  height: 17px;
  margin-top: 4px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv5 .b1 {
  width: 100px;
  height: 35px;
  margin-right: 15px;
  background: #fafafa;
  border-radius: 22px;
  opacity: 1;
}

/* .approvalDiv5_internal .b1 {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  /* background: #fafafa; */
/* border-radius: 22px;
  opacity: 1;
 } */

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv5 .b1 img {
  width: 80px;
  height: 20px;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: 12px;
  margin-right: 17px;
}

.approvalDiv5_internal .b1 img {
  width: 5em;
  height: 2em;
  margin-left: 10px;
  margin-bottom: 12px;
  margin-right: 17px;
}

.mainPopup {
  position: fixed;
  left: 0%;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.DeclinedAndpopup {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  top: 246px;
  left: 403px;
  background: var(--Blue_3);

  border-radius: 20px;
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (min-width: 300px) and (max-width: 480px) {
  .DeclinedAndpopup {
    width: 335px !important;
    height: 270px !important;
    top: 150px;
    left: 12px;
    padding: 10px;
  }

  .DeclinedAndpopup .benefbtnconfandCan {
    margin-bottom: 0px !important;
  }

  .DeclinedAndpopup .declinedReasion p {
    width: 330px !important;
    height: 40px !important;
    white-space: wrap !important;
    margin-left: 10px !important;
  }

  .DeclinedAndpopup .declinedReasion h2 {
    margin-left: 10px !important;
  }

  .DeclinedAndpopup .Beneinputfield {
    margin-left: 10px !important;
    margin-top: 1px;
  }
}

.DeclinedAndpopup .declinedReasion {
  display: flex;
  flex-direction: column;
}

.DeclinedAndpopup .declinedReasion h2 {
  width: 156px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
  /* margin-top: 30px; */
  margin-left: 30px;
}

.DeclinedAndpopup .declinedReasion p {
  width: 362px;
  height: 22px;
  text-align: left;
  font: normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9 !important;
  opacity: 1;
  white-space: nowrap;
  margin-left: 30px;
  margin-top: 10px;
}

@media only screen and (min-width: 300px) and (max-width: 480px) {
  .DeclinedAndpopup .declinedReasion p {
    width: 0px;
    height: 0px;
    white-space: wrap;
  }
  .DeclinedAndpopup .declinedReasion h2 {
    margin-top: 12px !important;
  }
}

/* @media only screen and (min-width: 300px) and (max-width: 350px) {
  .failed-action-message {
    width: 221px !important;
  }
} */
.DeclinedAndpopup .Beneinputfield {
  display: flex;
  position: relative;
  margin-left: 20px;
  margin-top: 20px;
}

.DeclinedAndpopup .Beneinputfield input {
  font-size: 16px;
  color: white;
  padding: 0px 10px;
  background-color: transparent;
  outline: none;
  opacity: 1;
}

.Beneinputfield label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #f9f9f9;
  padding: 0 2px;
  pointer-events: none;
  transition: 0.5s;
}

.DeclinedAndpopup .benefbtnconfandCan {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin: 20px 30px 20px 0px;
}

.DeclinedAndpopup .benefbtnconfandCan .confirmBtn {
  width: 68px;
  height: 32px;
  background-color: var(--Secondary_grey);
  /* opacity: 0.5; */
  border-radius: 20px;
  text-align: center;
  font-family: Open Sans;
  font-size: var(--Font_12);
  font-weight: var(--normal);
  letter-spacing: 0px;
  color: var(--Primary_white) !important;
}

.DeclinedAndpopup .benefbtnconfandCan .cancleBtn {
  width: 50px;
  height: 22px;
  text-align: center;
  text-decoration: underline;
  font-size: var(--Font_12);
  font-weight: var(--normal);
  font-family: Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.InternalAccountApproval_Acdiv3 {
  width: 341px;
  height: 100%;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 {
  width: 100%;
  height: 100%;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx1 {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx1 p {
  width: 135px;
  height: 27px;
  text-transform: lowercase;
  margin-top: 17px;
  margin-left: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .AprovalpageContainx1
  p::first-line {
  text-transform: capitalize !important;
}

/* .InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx1 span {
  width: 100px;
  height: 27px;
  margin-top: 20px;
  margin-left: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
} */

.InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx2 {
  /* margin-top: -2%; */
  display: flex;
  justify-content: space-between;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx2 .b1 {
  width: 89px;
  height: 15px;
  text-align: left;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 20px;
  margin-top: -2px;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .AprovalpageContainx2 .b2 {
  /* width: 80px; */
  height: 15px;
  margin-top: 4px;
  margin-left: 20px;
  text-align: right;
  font: normal normal normal var(--Font_12) / 19px var(--toastify-font-family);
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  margin-top: -2px;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo {
  display: flex;
  height: 50%;
  width: 80%;
  margin-left: 68px;
  margin-top: 40px;
  flex-direction: end;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 {
  display: flex;
  flex-direction: end;
  justify-content: space-between;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--Blue_8) 0% 0% no-repeat padding-box;
  border-radius: 28px 0px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 .expentraloader {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 29px;
  margin-top: 35px;
  margin-left: 110px;
  opacity: 1;
  align-items: center;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 .errorApproval {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 60px;
  width: auto;
  margin-top: 20px;
  margin-left: 20px;
}

.errorApproval {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 60px;
  width: auto;
  margin: 20px;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Unknownpage
  .Unknownpage1
  img {
  width: 40px;
  height: 40px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 25px;
}

.errorApproval .failed img {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Unknownpage
  .Unknownpage2 {
  width: auto;
  height: 60px;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Unknownpage
  .Unknownpage2
  span {
  width: 118px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.mobilefailed span {
  width: 118px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Unknownpage
  .Unknownpage2
  p {
  width: 169px;
  height: 34px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.logo .logo1 .errorApproval .mobilefailed p {
  width: 300px;
  height: auto;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.loaderApproval {
  width: 300px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 .Declinedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5860 0% 0% no-repeat padding-box;
  border-radius: 30px 0px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Declinedpage
  .Declinedpage1 {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Declinedpage
  .Declinedpage2 {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Declinedpage
  .Declinedpage2
  span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Declinedpage
  .Declinedpage2
  p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 .Approvedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px;
  opacity: 1;
  background: #3cb371 0% 0% no-repeat padding-box;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvedpage
  .Approvedpage1 {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvedpage
  .Approvedpage2 {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvedpage
  .Approvedpage2
  span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvedpage
  .Approvedpage2
  p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalleftside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 5px; */
  margin-bottom: 1px;
  gap: 04px;
  width: 50%;
  align-items: center;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalleftside
  img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalleftside
  p {
  width: 34px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

/* .InternalAccountApproval_Acdiv3 .Approvalsection3 .logo .logo1 .approvaldivider {
  width: 0px;
  height: 100%;
  border: 1px solid #1d3a6d;
  opacity: 1;
} */

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalrightside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 5px; */
  /* border-bottom-right-radius: 20px; */
  width: 50%;
  gap: 04px;
}

.Approvalrightside:hover {
  border-bottom-right-radius: 28px;
  background-color: var(--Blue_8_hover);
}

.Approvalleftside:hover {
  border-top-left-radius: 28px;
  background-color: var(--Blue_8_hover);
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalrightside
  img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.InternalAccountApproval_Acdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvalrightside
  p {
  width: 40px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.BeneAccount_NoAccountFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 95px;
  /* gap: 61px; */
  opacity: 1;
}

.BeneAccount_LeftTextContentNoAccount {
  width: 274px;
  height: 44px;
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.9;
  padding-bottom: 90px;
}

.BeneAccount_RightTextContentNoAccount {
  width: 340px;
  height: 261px;
  opacity: 1;
}

#requireApprovalScroll {
  overflow: auto;
  width: 100%;
  height: 73vh;
}

body::-webkit-scrollbar {
  display: none;
}

.requireApproval_scroller::-webkit-scrollbar {
  width: 10px;
}

.requireApproval_scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.requireApproval_scroller::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 100px;
}

.confirm-btn-enable {
  width: 66px;
  height: 32px;
  background: var(--Button_green);
  border-radius: 20px;
  border: none;
  opacity: 1;
  color: var(--Primary_white);
  font-size: var(--Font_12);
  font-weight: var(--Font_semibold);
  font-family: Open Sans;
  cursor: pointer;
}
.InternalheadingAccountDetail {
  font: normal normal 600 20px/39px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}
.Internalheadertextbottomv {
  font: normal normal 600 14px/23px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
}
.InternalAccountheadingAccountDetail {
  font: normal normal 600 20px/39px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}
.Internal_approval_heder_containerm {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 56px;
}

.Internal_approvalheadertext {
  width: 100%;
  border-bottom: 1px solid #1d3a6d !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  height: 100%;
  background: #3a5686 !important;
}

.Internal_approvalheaderarrow {
  display: inline-block !important;
  width: 10%;
  cursor: pointer;
  padding: 10px;
}

.InternalAccountheadertextm {
  display: flex !important;
  flex-direction: column !important;
  gap: 2px !important;
  width: 90%;
  padding: 0 !important;
}

.allBeneapprovaltext {
  width: 100%;
  padding: 15px 20px !important;
  color: #dadada !important;
  background: #3a5686;
  border-bottom: 0;
}

.bene_approval_containerm {
  width: 100%;
  margin-top: 56px !important;
}

.SecondheaderApprovalm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px !important;
  color: #c9c9c9;
}

.navbarleftm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
  text-align: left;
}

.navbarrightm {
  display: flex;
  flex-direction: column;
  gap: 04px;
  width: 50%;
  text-align: right;
}

.approvedAcoutDetailsm {
  width: auto !important;
  background: #314d7e;
  border-radius: 20px !important;
  margin: 0 15px 20px 15px !important;
}

.ApprovalAcdivm1 {
  width: 100%;
  height: 100%;
  /* border-bottom: 2px solid #506994; */
  border-bottom: 1px solid #1d3a6d !important;
}

.ApprovalAcdivm1 .approvaltext4 {
  margin-top: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ApprovalAcdivm1 .approvaltext4 p {
  width: 300px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}
.ApprovalAcdivm1 .approvaltext4 span {
  width: 111px;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 50px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}
.ApprovalAcdivm1 .Approvalsection1 {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.ApprovalAcdivm1 .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.approvaltext1m :first-child {
  width: initial !important;
  height: 19px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9 !important;
  opacity: 1;
}

.approvaltext1m :first-child::first-line {
  text-transform: capitalize !important;
}

.approvalDiv1m :first-child {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 22px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9 !important;
  opacity: 1;
}

.approvalDiv1m :first-child::first-line {
  text-transform: capitalize !important;
}

.InternalAccountApproval_Acdiv2 .Approvalsection2 .approvalDiv1 p::first-line {
  text-transform: capitalize !important;
}

.approvalCategorym :first-child {
  /* width: 400px; */
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px / 19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.approvalCategorym :first-child ::first-line {
  text-transform: capitalize !important;
}

.approvalRequestedmChild {
  width: 150px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-top: 20px; */
  /* margin-left: 20px; */
  text-align: left;
  font: normal normal normal 14px / 19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9 !important;
  opacity: 1;
  text-transform: capitalize !important;
}

.approvalRequestedmChild ::first-line {
  text-transform: capitalize !important;
}

.AprovalpageContainx1 p ::first-line {
  text-transform: capitalize !important;
}

.createdOnDate {
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  font-family: var(--toastify-font-family);
  font-weight: var(--Font_normal);
  font-size: var(--Font_14);
  color: var(--Primary_white);
  line-height: 19.07px;
  letter-spacing: 0;
}

.moreDetailsButton {
  width: 134px;
  height: 46px;
  background-color: var(--Primary_white);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.moreDetailsButton:hover {
  background-color: var(--White_hover);
}

.moreDetailsText {
  font-family: var(--toastify-font-family);
  font-weight: var(--Font_semibold);
  font-size: var(--Font_12);
  color: var(--Blue_3);
  line-height: 16.34px;
  letter-spacing: 0;
}

.moreDetails_ArrowButton {
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 800px) {
  #BeneviewAllScrollContainerBarbene {
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  /* .mainPopup{
    bottom: 0 !important;
  } */

  .DeclinedAndpopup .declinedReasion h2 {
    font: normal normal 600 16px/27px Open Sans !important;
    margin-top: 20px !important;
  }

  .DeclinedAndpopup .declinedReasion p {
    font: normal normal 14px/22px Open Sans !important;
    white-space: wrap !important;
    /* width: 298px !important; */
  }

  .DeclinedAndpopup .Beneinputfield input {
    font-size: 14px !important;
    width: 100% !important;
    margin-right: 10px;
  }

  .Beneinputfield label {
    font-size: 14px !important;
  }

  .BeneTxn_benefbtnconfandCan .confirmBtn,
  .BeneTxn_benefbtnconfandCan .cancleBtn {
    font: normal normal normal 14px/14px Open Sans !important;
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .DeclinedAndpopup .declinedReasion h2 {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 475px) {
  .txn_note {
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 480px) {
  .txn_note {
    padding-left: 30px !important;
  }
}

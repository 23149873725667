.totp_backup_codes_left {
  width: 472px;
  height: 326px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Blue_5);
}

.totp_backup_codes_right {
  display: flex;
  flex-direction: column;
  width: 472px;
  height: fit-content;
}

.totp_backup_codes_right_top > p {
  width: 388px;
  height: 38px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
}

.totp_backup_codes_right_top > img {
  width: 23.06px;
  height: 23.06px;
}

.totp_backup_codes_right_top {
  width: 100%;
  height: 78px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Highlight_orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totp_backup_codes_right_bottom {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 472px;
  height: 316px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Blue_6);
  margin-top: 20px;
  flex-direction: column;
}

.totp_backup_codes_right_bottom > img {
  align-self: center;
}

.totp_backup_codes_left_1 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin-bottom: 20px;
}

.totp_backup_codes_left_2 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
  margin-bottom: 20px;
}

.totp_backup_codes_left_3 {
  width: 432px;
  height: 140px;
  border-radius: 10px;
  background-color: var(--Blue_6);
  margin-bottom: 20px;
  position: relative;
}

.totp_backup_codes_left_4 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
}

.totp_backup_codes_left_copy {
  width: 34px;
  height: 34px;
  border-radius: 0px 10px 0px 10px;
  background-color: var(--Blue_8);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.totp_backup_codes_left_copy > img {
  width: 13.33px;
  height: 13.4px;
}

.backup_copy_msg {
  background-color: var(--Primary_black);
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
  position: absolute;
  border-radius: 5px;
  padding: 5px;
  right: -8px;
  top: 38px;
}

.totp_backup_codes_left_all_backup > div {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
  padding: 1px;
  margin-bottom: 8px;
  break-inside: avoid; /* Prevents breaking inside columns */
  text-align: center;
}

.totp_backup_codes_left_all_backup {
  column-count: 2;
  column-gap: 20px;
  padding: 8px 0px;
}

.totp_backup_codes_left_all_backup::before {
  content: "";
  position: absolute;
  background-color: var(--Blue_3);
  width: 1px;
  height: 120px;
  top: 11px;
  left: 50%;
}

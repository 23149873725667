.totp_verify_left {
  width: 472px;
  height: 282px;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}

.totp_verify_right {
  width: 472px;
  height: 290px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--Blue_5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.totp_verify_right > img {
  width: 287.8px;
  height: 250px;
}

.totp_verify_main_div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--Blue_5);
  width: 100%;
  height: 200px;
  padding: 20px;
  border-radius: 20px;
  z-index: 2;
}

.totp_verify_main_div > h4 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
}

.totp_verify_main_div > p {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_grey);
  margin-top: 10px;
  margin-bottom: 20px;
}

.input_Field_totp {
  border: 1px solid var(--Primary_grey);
  border-radius: 10px;
  position: relative;
  transition: all 0.1s ease;
}

.input_Field_totp > img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 28%;
  right: 4%;
}

.input_Field_totp > label {
  position: absolute;
  color: var(--Primary_grey);
  margin-left: 15px;
  top: 8px;
  transition: all 0.1s ease;
  background-color: var(--Blue_5);
  padding: 0px 3px;
  cursor: text;
  z-index: 2;
}

.totp_verify_butt {
  width: 432px;
  height: 42px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--Secondary_grey);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 10px;
}

.totp_verify_butt:hover {
  opacity: 0.8;
}

.totp_verify_butt > img {
  width: 30px;
  height: 30px;
}

.totp_verify_left_error_msg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.totp_verify_left_error_msg_left {
  width: 19.22px;
  height: 19.22px;
  margin-right: 20px;
}

.totp_verify_left_error_msg_right > h4 {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
}

.totp_list {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Primary_white);
  position: relative;
  margin-left: 20px;
}

.totp_verify_left_error_msg_right > ul {
  list-style-type: disc;
  color: f9f9f9;
}
